import { template as template_519922ebbdbc4bc486abb21ccbc0539e } from "@ember/template-compiler";
const FKText = template_519922ebbdbc4bc486abb21ccbc0539e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
