import { template as template_2dd0afb778e34490bba9ed06e11c3f15 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2dd0afb778e34490bba9ed06e11c3f15(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
