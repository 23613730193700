import { template as template_e5bb6ed894dc4a658a405932480630dc } from "@ember/template-compiler";
const FKLabel = template_e5bb6ed894dc4a658a405932480630dc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
